import React, { Component } from "react";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import Nav from "../component/Nav";
import AllConstant from "../helpers/AllConstant";
import { Seo } from "../helpers/Seo";

import Moment from 'react-moment';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
  } from "react-share";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: []
        };
    }
    
    componentDidMount() {
        Seo({
            title: 'Actualités > GREEN TECH INNOVATION',
            metaDescription: 'Nos récentes actualités, opportinutés, stage d\'emploi'
        });

        fetch(AllConstant.API_BASE_URL+"/api/posts")
            .then(res => res.json())
            .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result.posts
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    

    render() {
        const { error, isLoaded, items } = this.state;
        return (
            <div style={{ backgroundColor: "#F5F5F9" }}>
                <Nav />

                <Breadcrumb way="Accueil > Actualités" />

                <div>
                    <img src="/images/news_top.png" alt="" />
                </div>

                <section >
                    <div className="col-lg-8 col-sm-10 mx-auto">
                        
                        {items.map(item => (

                            <div className="bg-white mt-4 py-4 px-3 rounded">
                                <div className="hstack mb-4">
                                    <img src="/images/post_gti.png" className="rounded-circle border" height={ "50px" } width={ "50px" }  alt="" />
                                    <div className="vstack ms-3">
                                        <h6>GREEN TECH INNOVATION</h6>
                                        <small>
                                            <Moment format="DD-MM-YYYY à hh:mm:ss">
                                            { item.created_at }
                                            </Moment>
                                        </small>
                                    </div>
                                </div>

                                <Link to={'/post/'+item.postSlug} className="text-decoration-none text-black fw-bold"><h6 className="postTitle">{item.postTitle}</h6></Link>

                                <p>
                                    {item.postDescription.substr(0, 250)+"..."}
                                </p>
                                
                                {
                                    (item.postImage !== "" || item.postImage !== "null") && 
                                    <img src={AllConstant.API_BASE_URL+"/"+item.postImage} className="rounded" style={{ width: "50%" }} alt="" />
                                }

                                <div className="ms-auto mt-3 text-black fw-bold fs-6 hstack dropup">
                                    <FacebookShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug}>
                                        <FacebookIcon size={40} />
                                    </FacebookShareButton>
                                    <EmailShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug} className="ms-3">
                                        <EmailIcon size={40} />
                                    </EmailShareButton>
                                    <LinkedinShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug} className="ms-3">
                                        <LinkedinIcon size={40} />
                                    </LinkedinShareButton>
                                    <TelegramShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug}  className="ms-3">
                                        <TelegramIcon size={40} />
                                    </TelegramShareButton>
                                    <TwitterShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug}  className="ms-3">
                                        <TwitterIcon size={40} />
                                    </TwitterShareButton>
                                    <WhatsappShareButton url={AllConstant.APP_URL+'/post/'+item.postSlug}  className="ms-3">
                                        <WhatsappIcon size={40} />
                                    </WhatsappShareButton>
                                </div>
                                
                            </div>

                        ))}

                        <div className="mb-4"></div>

                    </div>    
                </section>

                <Footer />
            </div>
        );
    }
}

export default translate()(News);